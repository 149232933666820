@font-face {
  font-family: 'KumbhSans';
  src: local('KumbhSans'), url(/public/KumbhSans-Black.ttf) format('ttf');
}

#homepage-body {
  background-color: black;
  width: 100%;
  height: 100vh;
}

#homepage-info {
  color: white;
  font-size: 4vw; /* Adjusted font size proportionally */
  position: absolute;
  left: 50%; /* Center horizontally */
  top: 15%; /* Adjusted from padding-top to top */
  transform: translateX(-20%); /* Adjusted positioning */
  transform: translateY(70%);
}

#homepage-info {
  width: 22ch;
  animation: typing 2s steps(22), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-size: 3.5vw;
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

#person-waving-img {
  position: absolute;
  left: 5%; /* Adjusted padding-right to left */
  top: 20%; /* Adjusted padding-top */
  max-width: 40%; /* Adjusted proportionally */
}

#footer-note-1 {
  color: white;
  position: absolute;
  left: 1%; /* Adjusted padding-right to right */
  bottom: 1%; /* Adjusted padding-top to bottom */
  font-size: medium;
}

/* Media Query for Phones */
@media only screen and (max-width: 600px) {
  #homepage-info {
      font-size: 7vw; /* Adjusted font size for smaller screens */
      left: 5%; /* Center horizontally for smaller screens */
      top: 45%; /* Adjusted top positioning for smaller screens */
      transform: translateX(-50%); /* Adjusted positioning for smaller screens */
      transform: translateY(140%);
  }

  #person-waving-img {
      left: 10%; /* Adjusted left positioning for smaller screens */
      top: 15%; /* Adjusted top positioning for smaller screens */
      max-width: 80%; /* Adjusted max-width for smaller screens */
  }

  #footer-note-1 {
      font-size: small; /* Adjusted font size for smaller screens */
      left: 1%; /* Adjusted left positioning for smaller screens */
      bottom: 1%; /* Adjusted bottom positioning for smaller screens */
  }
}
