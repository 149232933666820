.privacy-policy-container {
  max-width: 900px;
  margin: 2rem auto;
  padding: 0 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.policy-nav {
  margin-bottom: 2rem;
}

.back-button {
  display: inline-flex;
  align-items: center;
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  transition: transform 0.2s ease;
}

.back-button:hover {
  transform: translateX(-4px);
}

.policy-header {
  text-align: center;
  margin-bottom: 3rem;
}

.policy-sections {
  display: grid;
  gap: 2rem;
}

.policy-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.policy-card:hover {
  transform: translateY(-2px);
}

.policy-card h2 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.policy-card p {
  color: #606f7b;
  line-height: 1.7;
  margin-bottom: 1rem;
}

.policy-card ul {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.policy-card li {
  color: #606f7b;
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

.privacy-policy-container h1,
.privacy-policy-container h2,
.privacy-policy-container h3 {
  color: #333;
}

.privacy-policy-container p {
  color: #555;
  line-height: 1.6;
}

.privacy-policy-container ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #555;
}

.privacy-policy-container ul li {
  margin-bottom: 0.5rem;
}