.subleasy-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-section {
  text-align: center;
  padding: 4rem 0;
  margin-bottom: 3rem;
}

.subleasy-logo {
  width: 120px;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.subleasy-logo:hover {
  transform: scale(1.05);
}

.tagline {
  font-size: 1.5rem;
  color: #6c757d;
  margin: 1rem 0 2rem;
}

.cta-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  background: #0056b3;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.privacy-policy-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.privacy-policy-link:hover {
  text-decoration: underline;
}

.subleasy-content {
  text-align: left;
  margin-top: 2rem;
}

.subleasy-content h2 {
  color: #333;
  margin-bottom: 1rem;
}

.subleasy-content p {
  color: #555;
  line-height: 1.6;
}

.subleasy-content ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #555;
}

.subleasy-content ul li {
  margin-bottom: 0.5rem;
}
