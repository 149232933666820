@font-face {
    font-family: 'KumbhSans';
    src: local('KumbhSans'), url(/public/KumbhSans-Black.ttf) format('ttf');
}
#about-body {
    background-color: black;
    width: 100%;
    height: 100vh;
}

#about-info {
    color: white;
    font-size: x-large; /* Adjusted font size proportionally */
    position: absolute;
    left: 10%; /* Center horizontally */
    bottom: 25%; /* Adjusted from padding-top to top */
    transform: translate(-1%, 5%); /* Adjusted positioning */
}

#person-hello-img {
    position: absolute;
    left: 6%; /* Adjusted padding-right to left */
    top: 15%; /* Adjusted padding-top */
    max-width: 20%; /* Adjusted proportionally */
}

#about-footer-note-1 {
    color: white;
    position: absolute;
    left: 1%; /* Adjusted padding-right to right */
    bottom: 1%; /* Adjusted padding-top to bottom */
    font-size: medium; /* Adjusted font size proportionally */
}

/* Media Query for Phones */
@media only screen and (max-width: 600px) {
    #about-info {
        font-size: large; /* Adjusted font size for smaller screens */
        left: 5%; /* Adjusted left positioning for smaller screens */
        bottom: 12%; /* Adjusted top positioning for smaller screens */
        transform: translate(-1%, 3%); /* Adjusted positioning for smaller screens */
    }

    #person-hello-img {
        left: 3%; /* Adjusted left positioning for smaller screens */
        top: 10%; /* Adjusted top positioning for smaller screens */
        max-width: 30%; /* Adjusted max-width for smaller screens */
    }

    #about-footer-note-1 {
        font-size: small; /* Adjusted font size for smaller screens */
        left: 1%; /* Adjusted left positioning for smaller screens */
        bottom: 1%; /* Adjusted bottom positioning for smaller screens */
    }
}
