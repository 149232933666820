@font-face {
  font-family: 'KumbhSans';
  src: local('KumbhSans'), url(/public/KumbhSans-Black.ttf) format('ttf');
}

#experiences-body {
  background-color: black;
  width: 100%;
  /* min-height: 145vh; */
  height: 100%;
  position: relative;
}

#experiences-button-container {
  position: relative; /* Added positioning for button container */
}

.post-content {
  color: white;
  width: 100%; /* Adjusted width to ensure text fits on the page */
  max-width: 800px; /* Added maximum width to limit text expansion on larger screens */
  margin: 0 auto; /* Center the text horizontally */
  transform: translateY(1.3%);
  padding: 20px; /* Added padding for better readability and spacing */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  font-size: large;
  line-height: 170%;
}



a {
  color: aquamarine;
}

a:hover {
  color: blue;
}

h3 {
  color: white;
  font-weight: bolder;
  line-height: 200%;
}


#experiences-footer-note-1 {
  color: white;
  position: relative;
  left: 1%; /* Adjusted padding-right to right */
  /* bottom: 90%; Adjusted padding-top to bottom */
  /* transform: translateY(305%); */
  background-color: black;
  font-size: medium;
}

/* Media Query for Phones */
@media only screen and (max-width: 600px) {
  #experiences-button-container button {
    transform: none; /* Reset the transform for smaller screens */
    margin: 0 auto; /* Center the button horizontally */
  }

  .post-content {
    width: 90%; /* Adjust width for smaller screens */
    padding: 5px; /* Adjust padding for smaller screens */
    font-size: medium; /* Adjust font size for smaller screens */
    transform: translateY(2%);
    line-height: 160%; /* Adjust line height for smaller screens */
  }
}
